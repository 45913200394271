.sample-pickup-form-page-container {
    height: 100vh !important;
    .loader-masked {
      position: absolute;
      width: 100%;
      height: 100%;
      background: transparent;
      top: 0;
      left: 0;
      z-index: 4;
    }
  }
  
  .sample-pickup-form-content-container {
    height: 100vh !important;
  }
  
  .sample-pickup-form-heading {
    color: var(--black-85);
    font-size: var(--font-size-heading-l4);
    font-family: "Poppins";
    font-weight: 500;
    line-height: var(--line-height-heading-l4);
  }
  
  .sample-pickup-form-container {
    overflow: auto;
    height: 70%;

    @media screen and (max-width: 768px) {
      .ant-row {
        flex-flow: inherit !important;
      }
  
      .ant-col-push-3 {
        left: 15% !important;
      }
      #scrollable-form-section-fields-container {
        flex: initial;
        max-width: 70% !important;
      }
    }
  }
  
  .sample-pickup-form-header {
    display: flex;
    align-items: center;
  }
  
  .form-header,
  .form-title-row {
    margin-bottom: 0 !important;
  }
  
  .ant-select-selection-search {
    right: 2.5rem !important;
  }
  
  @media screen and (max-width: 768px) {
    .ant-col-push-4 {
      left: 0;
    }
  }
  